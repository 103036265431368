import React, { useState } from "react"
import { Col, Form, Button } from "react-bootstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
import { navigate } from "gatsby-link"

import { StaticImage } from "gatsby-plugin-image"
import { FaSpinner } from "react-icons/fa"
// Styles
import * as styles from "../styles/components/DiscountModal.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const DiscountForm = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            location
          }
        }
      }
    }
  `)

  const [name, setName] = useState("")
  const [birthday, setBirthday] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Discount",
        name,
        birthday,
        email,
        tel,
      }),
    })
      .then(() => {
        setLoading(false)
        navigate(form.getAttribute("action"))
      })
      .catch(error => console.log(error))
  }

  return (
    <Form
      className={`pb-5 mb-5 pt-3 ${styles.form}`}
      name="Discount"
      method="post"
      data-netlify="true"
      action="/success"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="Discount" />

      <div className="my-5 text-center">
        <div className="p-5">
          <StaticImage
            src="../images/icon.png"
            alt="logo"
            placeholder="blurred"
          />
        </div>

        <h3 className="fw-bold text-uppercase">
          20% Discount For All Group Members
        </h3>
        <p>Please fill in the form below to register!</p>
      </div>

      <Col className="py-3">
        <Form.Label>Patient’s name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Patient’s name"
          size="lg"
          required
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </Col>
      <Col className="py-3">
        <Form.Label>Patient’s date of birth</Form.Label>
        <Form.Control
          type="date"
          name="birthday"
          placeholder="Patient’s date of birth"
          size="lg"
          required
          value={birthday}
          onChange={e => setBirthday(e.target.value)}
        />
      </Col>
      <Col className="py-3">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Email Address"
          size="lg"
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Col>
      <Col className="py-3">
        <Form.Label>Contact number</Form.Label>
        <Form.Control
          type="tel"
          name="tel"
          placeholder="Contact number"
          size="lg"
          required
          value={tel}
          onChange={e => setTel(e.target.value)}
        />
      </Col>

      <Button
        type="submit"
        className="mt-3 w-100"
        disabled={loading || !name || !email || !tel || !birthday}
      >
        {loading ? <FaSpinner className="spinner" /> : "Submit"}
      </Button>
    </Form>
  )
}

export default DiscountForm

// <Form
// className={`py-4 ${styles.form}`}
// name='discount-form'
// method='post'
// data-netlify='true'
// action='/success'
// data-netlify-honeypot='bot-field'
// onSubmit={handleSubmit}
// >
// <div className='my-5 text-center'>
//   <div className='p-5'>
//     <StaticImage
//       src='../images/icon.png'
//       alt='logo'
//       placeholder='blurred'
//     />
//   </div>

//   <h3 className='fw-bold text-uppercase'>
//     20% Discount For All Group Members
//   </h3>
//   <p>Please fill in the form below to register!</p>
// </div>

// <Form.Group className='mb-3'>
//   <Form.Label>Name</Form.Label>
//   <Form.Control
//     type='text'
//     name='name'
//     placeholder='Your Name'
//     size='lg'
//     required
//     value={name}
//     onChange={e => setName(e.target.value)}
//   />
// </Form.Group>
// <Form.Group className='mb-3'>
//   <Form.Label>Email address</Form.Label>
//   <Form.Control
//     name='email'
//     size='lg'
//     type='email'
//     placeholder='name@example.com'
//     value={email}
//     required
//     onChange={e => setEmail(e.target.value)}
//   />
// </Form.Group>
// <Form.Group className='mb-3'>
//   <Form.Label>Phone Number</Form.Label>
//   <Form.Control
//     name='tel'
//     size='lg'
//     type='tel'
//     placeholder='Telephone Number'
//     value={tel}
//     required
//     onChange={e => setTel(e.target.value)}
//   />
// </Form.Group>
// <Button variant='light' onClick={handleClose}>
//   Close
// </Button>
// <Button
//   variant='primary'
//   type='submit'
//   disabled={loading || !name || !email || !tel}
// >
//   {loading ? <FaSpinner className='spinner' /> : 'Submit'}
// </Button>
// </Form>

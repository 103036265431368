import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DiscountForm from "../components/discountForm"
import { Link } from "gatsby"
import { Container, Button } from "react-bootstrap"

const DiscountProgram = () => (
  <Layout>
    <Seo
      title="Commercial Loyalty Program"
      description="All Commercial Group Members Get 20% OFF!"
    />
    <Container>
      <Link to="/blog/commercial-discount">
        <Button className="mt-4 " variant="light">
          Go Back
        </Button>
      </Link>
      <div className="justify-content-center d-flex">
        <DiscountForm />
      </div>
    </Container>
  </Layout>
)

export default DiscountProgram
